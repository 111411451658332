import { permissoesEnum } from "@/types/enums";

export default defineNuxtRouteMiddleware((to) => {
  const { $ability } = useNuxtApp();

  const routeName = String(to.name);
  const id = Number(to.params.id);

  // página de listagem
  if (
    routeName === "permissoes" &&
    $ability.cannot(permissoesEnum.VER_ROTA, "all")
  ) {
    return navigateTo({
      name: "index",
    });
  }

  // página de cadastro
  if (
    routeName === "permissoes-cadastrar" &&
    $ability.cannot(permissoesEnum.CADASTRAR_GRUPO_ACESSO, "all")
  ) {
    return navigateTo({
      name: "permissoes",
    });
  }

  // página de visualização
  if (
    routeName === "permissoes-visualizar-id" &&
    $ability.cannot(permissoesEnum.VISUALIZAR_GRUPO_ACESSO, "all")
  ) {
    return navigateTo({
      name: "permissoes",
    });
  }

  // página de edição
  if (
    routeName === "permissoes-editar-id" &&
    $ability.cannot(permissoesEnum.EDITAR_GRUPO_ACESSO, "all")
  ) {
    return navigateTo({
      name: "permissoes-visualizar-id",
      params: {
        id,
      },
    });
  }
});
